<template>
 <v-theme-provider>
  <base-section
    id="washingandcarpeting"
    space="36"
  >
    <v-spacer />

    <div v-if="$route.name=='세탁 및 카페트용'">
      <v-tabs
        class="hidden-sm-and-down"
        optional
        background-color="transparent"
      >
        <v-tab
          v-for="(name, i) in items"
          :key="i"
          :to="{ name }"
          :exact="name === 'Home'"
          :ripple="false"
          class="font-weight-bold"
          min-width="96"
          text
        >
          {{ name }}
        </v-tab>
      </v-tabs>
    </div>

    <v-container>
      <base-section-heading title="세탁 및 카페트용" />
      <v-card
          max-width="1080"
          class="mx-auto"
        >
          <v-list
          three-line
          >
            <template v-for="(info, index) in iteminfos">
              <v-divider
                v-if="info.divider"
                :key="index"
              ></v-divider>

              <v-list-item
                v-else
                :key="info.title"
              >
                <v-list-item-avatar>
                  <v-img :src="info.avatar"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="info.title"></v-list-item-title>
                  <v-card-text v-html="info.subtitle"> </v-card-text>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
    </v-container>
  </base-section>
 </v-theme-provider>
</template>

<script>
  export default {
    name: 'Sectionwashingandcarpetingr',
    drawer: null,
    data: () => ({
      drawer: null,
      items: [
        '자동식기 세척용',
        '주방용 세척제',
        '위생용 세척제',
        '세탁 및 카페트용',
      ],
      inset: false,
      iteminfos: [
        { header: 'Today' },
        {
          avatar: '',
          title: '물수건, 시트, 작업복 등 상업세탁용 세척제',
          subtitle: '&mdash; 세척력과 유화력이 우수하여 작업복 및 주방복 등의 세척에 효과적입니다.<br>완전 중성세제로 모든 세제와 혼합 사용이 가능하며, 음이온계 성분이 함유되지 않아 폐수처리에 용이 합니다.<br>',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '카페트 전용 세척제(기계작업용)',
          subtitle: '&mdash; 저기포성 카페트 세척제로 세척력이 강하고 건조가 쉽게 됩니다.',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '카페트 얼룩, 껌 제거제',
          subtitle: '&mdash; 카페트에 묻은 껌, 얼룩제거용 세제로써 오물 용해력이 우수하며, 사용이 편리합니다.',
        },
      ],
    }),
    provide: {
      theme: { isDark: false },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },

    },

  }
</script>
<style scoped>
.v-subheader {
    font-size: 18px;
    color: coral !important;
}
.v-list-item__title {
    font-size: 18px;
    color: coral !important;
}
</style>
